import type { AddReviewsReqType, ReviewByIdType, ReviewIdReqType } from '~/composables/reviews/types/ReviewsType';

type GetReviewsFunction<T> = (params: T & ReviewIdReqType) => Promise<Array<ReviewByIdType & T>>;
type AddReviewFunction<T> = (data: T & AddReviewsReqType) => Promise<boolean>;

export class Reviews<T> {
  private readonly getReviewsFunc: GetReviewsFunction<T>;
  private readonly addReviewFunc: AddReviewFunction<T>;
  private readonly params: T & ReviewIdReqType;

  constructor (params: ReviewIdReqType & T, getReviewsFunc: GetReviewsFunction<T>, addReviewFunc: AddReviewFunction<T>) {
    this.getReviewsFunc = getReviewsFunc;
    this.addReviewFunc = addReviewFunc;
    this.params = params;
  }

  static create<T> (params: T, getReviewsFunc: GetReviewsFunction<T>, addReviewFunc: AddReviewFunction<T>): Reviews<T> | null {
    if (!params) {
      return null;
    }

    return new Reviews<T>(params, getReviewsFunc, addReviewFunc);
  }

  async addReview (data:AddReviewsReqType): Promise<boolean> {
    if (!this.addReviewFunc) {
      return false;
    }

    return await this.addReviewFunc({ ...this.params, ...data }).catch((err) => {
      throw err;
    });
  }

  async getReviews (data?:ReviewIdReqType): Promise<Array<ReviewByIdType & T>> {
	  if (!this.getReviewsFunc) {
		  return [];
	  }

    try {
      return await this.getReviewsFunc({ ...this.params, ...data });
    } catch (err:any) {
      console.error(err.data.message);

      return [];
    }
  }
}
